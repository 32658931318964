<template>
  <!-- 业务审核管理列表 -->
  <div class="contract-file-manage-list">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="resetFrom"
      ref="formData"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        />
      </div>
    </base-form>
    <!-- 数据表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getApprovalTodoList'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
        <!-- <icon-button  @click="audit(scope.row,'E')"  content="审核"  icon="iconfont iconshenhe1"/> -->
        <icon-button  @click="audit(scope.row,'E')"  content="审核" v-if="scope.row.status === '1' || scope.row.status === '2'" icon="iconfont iconshenhe1"/>
        <icon-button  @click="info(scope.row)"  content="查看" v-else icon="iconfont iconchakan"/>
        <!-- 融资状态!==80已近废弃的，都要显示废弃 -->
        <!-- <icon-button  @click="abandon(scope.row)"  content="作废" v-if='scope.row.finBusuinessInfoVO.reviewStatus!==80' icon="iconfont iconbiangeng"/> -->
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { progressApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'

import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import Storage from '@/utils/storage'
export default {
  name: 'contractFileManage',
  components: { baseForm, BaseTable, IconButton, BaseButton },
  data () {
    return {
      fold: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      },
      disabled: false,
      tableData: [],
      gysData: []
    }
  },
  computed: {
    api () {
      return financeAdmittanceApi
    },
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    }
  },
  mounted () {
    this.gysList()
    this.handleFilter()
  },
  activated () {
    this.handleFilter()
  },
  methods: {
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 审核
    async  audit (row) {
      console.log(row, 'rowrowrow')
      const discardData = { businessId: row.finBusuinessInfoVO.keyId }
      if (row.finBusuinessInfoVO.statusCode === 'SIGNATURE_SIGN' || row.finBusuinessInfoVO.statusCode === 'SIGNATURE') {
        this.$set(discardData, 'batchNum', row.finBusuinessInfoVO?.batchNum || -999)
      }
      const res = await financeAdmittanceApi.whetherDiscard(discardData)
      if (!res.data && res.code === '0') {
        return
      }
      // 领取任务
      if (row.status === '1') {
        const param = {
          businessId: row.finBusuinessInfoVO.keyId,
          instanceId: row.instanceId,
          processName: row.finBusuinessInfoVO.statusCode,
          taskId: row.taskId
        }
        progressApi.updatestatus(param).then(res => {
          if (res.data) {
            this.$router.push({ path: '/business/auditTypeList', query: { type: 'E' } })
            Storage.setSession('examine', row)
          }
        })
      }
      this.$router.push({ path: '/business/auditTypeList', query: { type: 'E' } })
      Storage.setSession('examine', row)
    },
    // 查看详情
    info (row) {
      this.$router.push({ path: '/business/auditTypeList', query: { type: 'V' } })
      Storage.setSession('examine', row)
    },
    // 废弃
    abandon (row) {
      this.$stateConfirm({
        title: '提示',
        message: '确认废弃吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        const data = { businessId: row.finBusuinessInfoVO.keyId }
        financeAdmittanceApi.approvalAbandon(data).then(res => {
          if (res.data) {
            this.handleFilter()
          }
        })
      })
    },
    // 获取供应商数据
    // gysList () {
    //   supplierApi.getList().then((res) => {
    //     if (res.success) {
    //       this.gysData = res.data
    //     }
    //   })
    // },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.queryParas = {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.contract-file-manage-list {
  height: 100%;
}
</style>
